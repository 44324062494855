import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div id="about">
      <section
        className="services__area-2 mt--120 pt-150 pb-100 p-relative"
        style={{
          background: `url(assets/img/bg/wave-bg-2.png)`,
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      >
        <div>
          <div className="about__area-2">
            <div className="row">
              <div
                className="col-xl-7 col-lg-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="about__thumb-2 p-relative m-img"
                  style={{ padding: 20 }}
                >
                  <img src="assets/img/about/about-2.png" alt="" />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="about__content" style={{ padding: 20 }}>
                  <div className="section__title section__title-3 mb-25">
                    <h2>
                      We're on a mission to foster emotional intelligence from a
                      young age.
                    </h2>
                  </div>
                  <p>
                    We believe that emotionally intelligent students are better
                    equipped to navigate the challenges of school and life
                    successfully. Our app is designed to help students,
                    educators, and parents embark on this transformative journey
                    together.
                  </p>
                  <div className="about__list">
                    <ul>
                      <li>
                        <span>
                          <i>
                            {" "}
                            <FaCheck />{" "}
                          </i>
                          Increase Self Awareness
                        </span>
                      </li>
                      <li>
                        <span>
                          <i>
                            {" "}
                            <FaCheck />{" "}
                          </i>
                          Develop Empathy and Compassion
                        </span>
                      </li>
                      <li>
                        <span>
                          <i>
                            {" "}
                            <FaCheck />{" "}
                          </i>
                          Unlock Personal Growth and Resilience
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* <Link to="/about" className="z-btn">
                    Find out more
                    <i>
                      {" "}
                      <CgArrowLongRight />{" "}
                    </i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
