import React from "react";
import {
  FaArrowAltCircleRight,
  FaArrowCircleRight,
  FaArrowRight,
  FaLongArrowAltRight,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div id="home">
      <section className="hero__area p-relative">
        <div className="hero__shape">
          {/* <img
            className="one"
            src="assets/img/icon/slider/03/icon-1.png"
            alt=""
          /> */}
          <img
            className="two no-mobile"
            src="assets/img/icon/slider/03/icon-2.png"
            alt=""
          />
          <img
            className="three no-mobile"
            src="assets/img/icon/slider/03/icon-3.png"
            alt=""
          />
          <img
            className="four no-mobile"
            src="assets/img/icon/slider/03/icon-4.png"
            alt=""
          />
          <img
            className="five no-mobile"
            src="assets/img/icon/slider/03/icon-6.png"
            alt=""
          />
          <img
            className="six no-mobile"
            src="assets/img/icon/slider/03/icon-7.png"
            alt=""
          />
        </div>
        <div className="hero__item hero__height d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-5 order-last">
                <div className="hero__thumb-wrapper ml-100 scene p-relative">
                  <div className="hero__thumb one">
                    <img
                      className="layer"
                      data-depth="0.2"
                      src="assets/img/slider/03/slider_1.png"
                      alt=""
                    />
                  </div>
                  <div className="hero__thumb two d-none d-md-block d-lg-none d-xl-block">
                    <img
                      className="layer"
                      data-depth="0.3"
                      src="assets/img/slider/03/slider_2.png"
                      alt=""
                    />
                  </div>
                  <div className="hero__thumb three d-none d-sm-block">
                    <img
                      className="layer"
                      data-depth="0.4"
                      src="assets/img/slider/03/slider_3.png"
                      alt=""
                    />
                  </div>
                  <div className="hero__thumb four d-none d-md-block d-lg-none d-xl-block">
                    <img
                      className="layer"
                      data-depth="0.5"
                      src="assets/img/slider/03/slider_4.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7 d-flex align-items-center">
                <div className="hero__content">
                  <h1>
                    Nurturing{" "}
                    <span
                      style={{ color: "#0155A6", background: "transparent" }}
                    >
                      Mental
                    </span>
                    <span
                      style={{
                        color: "#0155A6",
                        background: "transparent",
                        padding: 0,
                      }}
                    >
                      Wellbeing
                    </span>{" "}
                    in Schools
                  </h1>
                  <p className="secondary__title">
                    Empowering the youth for a brighter future, join our
                    mission.
                    {/* intelligent and supportive communities. */}
                  </p>
                  {/* <Link
                    target="_blank"
                    to="https://it-s-ok-prod.web.app/#/"
                    className="z-btn z-btn-border"
                  >
                    Start free trial
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
