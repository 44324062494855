import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaVimeoV,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <footer>
        <div className="footer__area grey-bg pt-50">
          <div className="footer__top pb-20">
            <div>
              <div className="center-container-row">
                <div>
                  <div
                    className="footer__widget footer__widget-3 mb-45 wow fadeInUp"
                    data-wow-delay=".8s"
                  >
                    <div className="footer__widget-title center-container-row">
                      <h4>Contact Us</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__info mb-35 center-container-row">
                        <ul>
                          <li>
                            <div className="icon theme-color ">
                              <i>
                                {" "}
                                <FaEnvelope />{" "}
                              </i>
                            </div>
                            <div className="text theme-color ">
                              <span>
                                <a
                                  href="mailto:support@itsoktech.com"
                                  target="_blank"
                                >
                                  support@itsoktech.com
                                </a>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="footer__social theme-social center-container-row">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/profile.php?id=100066880862971"
                              target="_blank"
                            >
                              <i>
                                <FaFacebookF />
                              </i>
                              <i>
                                <FaFacebookF />
                              </i>
                            </a>
                          </li>
                          {/* <li>
                            <a href="#">
                              <i>
                                <FaTwitter />{" "}
                              </i>
                              <i>
                                <FaTwitter />{" "}
                              </i>
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="https://www.instagram.com/itsoktech/"
                              target="_blank"
                            >
                              <i>
                                <FaInstagram />{" "}
                              </i>
                              <i>
                                <FaInstagram />{" "}
                              </i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__copyright-3">
            <div className="">
              <div className="footer__copyright-inner">
                <div className="center-container-row">
                  <div className="col-xl-12">
                    <div className="footer__copyright-text text-center pt-20 pb-20">
                      <p>
                        <Link
                          className="footer__copyright-text"
                          to="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                      <div className="pb-10 pt-10"> </div>

                      <p>
                        <Link
                          className="footer__copyright-text"
                          to="/terms-and-conditions"
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                      </p>

                      <div className="pb-10 pt-10"> </div>

                      <p>Copyright © 2023 All Rights Reserved </p>
                      <div className="pb-10 pt-10"> </div>
                      <p>Made with 💙 in South Africa</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
