import ClientsList from "./clients_list";
import Testimonials from "./testimonials";

const Clients = () => {
  return (
    <div id="clients">
      <ClientsList />
      <Testimonials />
    </div>
  );
};

export default Clients;
