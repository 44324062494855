import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import useGlobalContext from "../../shared/hooks/useGlobalContext";
import Sidebar from "../../shared/components/Sidebar";

const NavigationBar = ({
  h4class = "",
  btn_text = "Login",
  btn_class = "z-btn-3",
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();

  return (
    <>
      <header>
        <div className="header__area p-relative header__transparent">
          {/* <div className="header__shape p-absolute">
            <img src="assets/img/icon/slider/03/icon-5.png" alt="" />
          </div> */}
          <div
            id="header__sticky"
            className={
              stickyMenu
                ? `sticky header__bottom header__bottom-2 ${h4class && h4class}`
                : `header__bottom header__bottom-2 ${h4class && h4class}`
            }
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo-3">
                    <a href="#home">
                      <img
                        src="assets/img/logo/logo.png"
                        alt="logo"
                        style={{ aspectRatio: 1.6, height: 100 }}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu main-menu-3 menu_three_wrapper">
                      <nav id="mobile-menu">
                        <ul>
                          <li>
                            <a className="secondary__text" href="#home">
                              Home
                            </a>
                          </li>
                          <li>
                            <a className="secondary__text" href="#about">
                              About
                            </a>
                          </li>
                          <li>
                            <a className="secondary__text" href="#features">
                              Features
                            </a>
                          </li>
                          <li>
                            <a className="secondary__text" href="#clients">
                              Clients
                            </a>
                          </li>
                          {/* <li>
                            <a className="secondary__text" href="#pricing">
                              Pricing
                            </a>
                          </li> */}
                          <li>
                            <a className="secondary__text" href="#footer">
                              Contact us
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                      <Link
                        to="https://it-s-ok-prod.web.app/#/"
                        className={`z-btn ${btn_class}`}
                        target="_blank"
                      >
                        {btn_text}
                      </Link>
                    </div>
                    {/* <div
                      onClick={handleShow}
                      className="sidebar__menu d-lg-none"
                    >
                      <div
                        className="sidebar-toggle-btn sidebar-toggle-btn-3"
                        id="sidebar-toggle"
                      >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <Sidebar show={show} handleClose={handleClose} /> */}
    </>
  );
};

export default NavigationBar;
