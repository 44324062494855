import Slider from "react-slick";

const ClientsList = () => {
  // slick setting
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    fade: false,
    arrows: false,
  };
  const clientsData = [
    {
      id: 1,
      name: "St Peter's Boys",
      imageUrl: "St_Peters_Boys.jpeg",
    },
    {
      id: 2,
      name: "King David Victory Park",
      imageUrl: "Victory_Park_Primary_School.jpeg",
    },
    {
      id: 3,
      name: "IVA",
      imageUrl: "IVA_Global_Online.jpeg",
    },
    {
      id: 4,
      name: "Optimi",
      imageUrl: "Optimi.jpeg",
    },
    {
      id: 5,
      name: "Optimi Campus Lynnwood",
      imageUrl: "Optimi_Campus_Lynwood.jpeg",
    },
    {
      id: 6,
      name: "Centennial",
      imageUrl: "Centennial_Schools.jpeg",
    },
    {
      id: 7,
      name: "Teneo School",
      imageUrl: "Teneo_School.jpeg",
    },
    {
      id: 8,
      name: "Tomorrow Trust",
      imageUrl: "Tomorrow_Trust.jpeg",
    },
    {
      id: 9,
      name: "St Peter's Girls",
      imageUrl: "St_Peters_Girls.jpg",
    },
    // {
    //     id: 3,
    //     name: "Justin",
    //     title: "St Peters",
    //     text: "It'sOk has allowed us to connect with our students and be able to ",
    //   },
  ];

  return (
    <>
      <section className="testimoinal__area gradient-bg">
        <div className="container">
          <div
            className=""
            style={
              {
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }
            }
          >
            <div className="col-sm-6 col-xs-12">
              <div className="testimonial__content pt-50 pb-20">
                <div className="section__title section__title-3 mb-30">
                  <h2 className="white-color text-center">
                    Partnered with some of South Africa's top schools
                  </h2>
                </div>

                <Slider {...settings}>
                  {clientsData.map((client, index) => {
                    const clientLogoUrl = `assets/img/client-logos/${client.imageUrl}`;
                    return (
                      <div key={index} className="client-logo-container">
                        <div className="center-container-row">
                          <img
                            src={clientLogoUrl}
                            alt="client-logo"
                            style={{ maxWidth: 200, maxHeight: 200 }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientsList;
