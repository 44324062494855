// const privacyPolicyHtml = require("./privacy_policy.html");



const PrivacyPolicy = () => {

  const htmlContent = `
  <!DOCTYPE html>
<html>

<head>
    <title>It'sOk Privacy Policy</title>
    <style>
        body {
            padding: 101px;
        }
    </style>
</head>

<body>
    <h1>CG TECHNOLOGY GROUP (Pty) Ltd Privacy Policy:</h1>
    <p>
        &ldquo;It&#39;sOk&rdquo; is the name of the application owned by CG
        TECHNOLOGY GROUP (Pty) Ltd registration number 202006182707.
    </p>
    <h2>
        It&#39;sOk PRIVACY POLICY:
    </h2>
    <p>
        Thank you for visiting a Site owned and operated by CG TECHNOLOGY GROUP
        (Pty) Ltd, registration number 202006182707, or an App published by CG
        TECHNOLOGY GROUP (Pty) Ltd (from which you have linked to this Privacy
        Policy). &ldquo;Sites&rdquo; refers to Internet web sites and
        &ldquo;Apps&rdquo; refers to apps for use on mobile communication devices
        such as smartphones and tablets. &ldquo;Platforms&rdquo; refers to Sites
        and Apps collectively. Through the Platforms, It&#39;sOk aims to extend
        our mission to help children reach their highest potential.
        &ldquo;It&#39;sOk&rdquo; also is referred to as &ldquo;we,&rdquo;
        &ldquo;us&rdquo; or &ldquo;our&rdquo; in this Privacy Policy.
    </p>
    <h2>PRIVACY PRINCIPLES:</h2>
    <p>
        It&#39;sOk takes security and safety of all information very seriously and
        recognizes the importance of privacy concerns especially in the digital
        environment. With this in mind, we have adopted this Privacy Policy for
        our Platforms. On our Platforms, we collect personal information in two
        forms. One form is information that we expressly request you to provide in
        connection with certain activities. The second form is anonymous
        information that we automatically collect from your computer, phone or
        other device.
    </p>
    <h2>
        Purpose:
    </h2>
    <p>
        The purpose of It&#39;sOk is to provide children/students, parents and the
        school a digital platform to allow for emotional expression to take place.
        It&#39;sOk provides a platform for emotional expression with the aim to
        increase communication between the parent, child and school around the
        child/students&#39; emotions. It&#39;sOk aims to create positive habits
        around healthy emotional expression. It&#39;sOk takes the use of the
        application by all participants very seriously and the aim of the app is
        to allow for a space of emotional development and growth.
    </p>

    <h2>
        Our Privacy Policy is based on the following principles:
    </h2>
    <ol>
        <li>
            You are free to choose whether or not to engage in any activity on our
            Platforms that involves your submission of personal information, such as
            your name, email address, phone number or other information that can be
            used to identify or contact an individual, whether online or offline. We
            will ask you for such information expressly. Most of the activities on
            our Platforms do not require you to submit any personal information.
        </li>
        <li>
            When we ask you to submit personal information, we will explain in the
            sign-up or collection process how we will use the information (such as
            requesting an email address when you sign up for a newsletter). We will
            use the information only as we have stated, other than in Special
            Circumstances which are described below (such as in relation to law
            enforcement or a business transfer).
        </li>

        <li>
            We use the anonymous information that is automatically collected from
            your device, such as a computer&#39;s Internet Protocol address, a
            mobile device identifier, operating system, or general location such as
            area code, to support internal operations so that we can understand and
            monitor the usage of our Platforms and improve the performance of the
            Platforms.
        </li>

        <li>
            Third parties that we use to assist with the Platforms are required to
            follow this Privacy Policy.
        </li>
    </ol>

    <h2>
        ESPECIALLY FOR CHILDREN:
    </h2>
    <ol start="5">
        <li>
            Our collection of personal information on Platforms that are directed to
            children/minors (&ldquo;Children’s Platforms&rdquo;) is intended to
            follow the principles of the Protection of Personal Information Act,
            2013 (&ldquo;POPIA&rdquo;), a South African law designed to protect the
            online privacy of children as well as the Children’s Online Privacy
            Protection Act (&ldquo;COPPA&rdquo;), a U.S. law designed to protect the
            online privacy of children under the age of 13.
        </li>

        <li>
            We will obtain parental consent before asking for personal information
            on our Children&#39;s Platforms, unless the request for information
            falls within an exception that would be permitted under POPIA and COPPA.
        </li>

        <li>
            We do not make personal information collected from children publicly
            available nor do we enable children to so on our Platforms. We will not
            condition a child&#39;s participation in any activity or service on the
            disclosure of more personal information than is reasonably necessary to
            participate in an activity.
        </li>

        <li>
            Parents may contact us at support&#64;itsoktech.com to review, update or
            delete any of their children&#39;s personal information that we may have
            collected and to elect for us not to collect any additional personal
            information from their children. Parents are able to delete their
            child&#39;s account on the app at any time.
        </li>

        <li>
            We will update this Privacy Policy from time to time by posting the
            updated Privacy Policy online. We will not change how we handle
            previously collected information without providing notice and obtaining
            parental consent where necessary.
        </li>
    </ol>

    <p>
        The following describes our Privacy Policy as it relates to certain
        specific activities.
    </p>

    <h2>
        INFORMATION WE REQUEST ON OUR CHILDREN&#39;S PLATFORMS:
    </h2>
    <p>
        We will obtain parental consent before asking for personal information on
        our Children&#39;s Platforms, unless the request for information falls
        within an exception that would be permitted under POPIA and COPPA. We will
        explain how we will use the information and use it only for that purpose.
    </p>

    <h2>
        INFORMATION WE COLLECT AUTOMATICALLY: WHAT WE COLLECT:
    </h2>
    <p>
        The app collects an email and password from a consenting parent or legal
        guardian. The app never asks the child for any personal information such
        as: email address, cell phone number or physical address. All information
        that is collected by the app will only be used to enhance the performance
        and use of the app. The information entered by the child will never be
        made public nor sold to anyone third party companies.
    </p>

    <h2>
        COLLECTING AND USING CHILDREN&#39;S INFORMATION:
    </h2>
    <p>
        We do not require children to actively share any personal information in
        order to use this App. For example, children can view content, use tools
        and enjoy many features of the App without registration, purchasing or
        providing any personal information. All personal information about the
        child is provided by the parent or legal guardian. We do not ask the child
        for any personal information such as email addresses, cell phone numbers
        or home addresses. All the information that is needed about the child is
        given by the parent of the child. The parent has to create the child&#39;s
        account and in so doing acknowledges and accepts full responsibility for
        anything that occurs after registration and gives permission to the app
        for the child to use the app and consume the content provided by the app.
        The only input needed by the child to sign in to the app is a unique
        password that will be sent to the parent to provide to the child.
    </p>

    <h2>
        PERSONAL DATA OF MINORS:
    </h2>
    <p>
        Our services are intended to be used by minors only with the consent of
        their parents or legal representatives.
    </p>

    <h2>
        HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?
    </h2>
    <p>
        If data subject would like us to delete its data, we&#39;ll only keep
        information that we need for legal reasons, to resolve disputes, to
        enforce our agreements or to prevent any future contacts with data
        subject. Whenever we collect personal information from a child, we only
        keep the information for the time we need it to provide a service or for
        the time it&#39;s legally required to be kept on record. We use the
        anonymous information that is automatically collected to support the
        internal operations of the Platforms. Some of this information is
        collected through third-party analytics tools. Internal operations include
        the following: · To monitor metrics such as total number of visitors and
        traffic;
    </p>
    <ul>
        <li>To understand which activities and services are most popular;</li>
        <li>
            To understand the pages where visitors enter our Sites and the pages
            viewed;
        </li>
        <li>
            To reduce the repetition in the promotional messages that users see on
            our Platforms;
        </li>
        <li>
            To understand generally the location of our users;
        </li>
        <li>
            To recognize the browsers used to visit our Sites, so that the content
            is adapted for the relevant browser; and
        </li>
        <li>To diagnose and fix technology problems.</li>
    </ul>
    <p>
        To allow the school insights in to the general emotional state of the
        school and make the necessary changes to make the school a happier and
        emotionally healthier environment. To improve the quality of the app and
        build better features to aid the school, parent and student. When you
        visit a Platform, we may use cookies and similar technologies like pixels
        and web beacons, to collect this anonymous information for the purpose of
        the internal operations of our Platforms. A &ldquo;cookie&rdquo; is a
        piece of information that a web site or an app transfers to a user&#39;s
        device for record-keeping purposes. As a user moves from one page or one
        screen to another, a cookie is used to tell us that it is the same device.
        We also may use a cookie to keep track of your selections. You may set
        your device to accept or not accept cookies and you may delete any cookies
        that you have received. However, should you decide to disable any cookies
        we place on your device, you may not be able to use certain services or
        features on our Platforms. A &ldquo;web beacon&rdquo; is a one-pixel image
        (which may be visible or transparent), which may be embedded on a web page
        or e-mail message, and which may also provide us with information about
        usage of that page or e-mail message.
    </p>

    <h2>
        HOW THE DATA COLLECTED IS USED AND SHARED IN THE APP:
    </h2>
    <p>
        The data that is shared in the app is shared in the following ways:
    </p>
    <ul>
        <li>
            School data: A school profile or many profiles is made available in order for the school to analyze general
            data
            in relation to the school emotions. This allows the school to analyze data on a grade level, class level or
            the
            whole school. Individual student emotions are not made available to analyze.
        </li>
        <li>
            School communication data: In using the “Speak to Someone” tool made available to the children/students the
            child/student has the ability to communicate with a staff member that is in some way associated with that
            school.
            This person can be, but is not limited to a social worker, psychologist, teacher or mentor. The
            child/student is
            never forced to communicate with the school, but it is offered as an option to aid the child/student. If a
            child/student opts to communicate with someone at the school certain information will be made available to
            that
            person in the form of a message. This information includes the emotion the child/student has selected, and
            the
            reasons associated with that emotion if the child/student selects any. The Child/student also has the option
            to
            type a message to send to the chosen school profile and the chosen school profile can type a message back.
            These
            messages are private and only exist between the child/student and the school profile the child/student
            chooses to
            message.
        </li>
        <li>
            Parent data: Data is also presented to the parent in the form of different reports. This data is only made
            available through the child/students use of the app.
        </li>
    </ul>

    <h2>
        MARKETING AND SERVICES:
    </h2>
    <p>
        It&#39;sOk may use Personal Information to provide you with materials
        about offers, products, and Services that may be of interest, including
        new content or Services. It&#39;sOk may provide you with these materials
        by phone or email, as permitted by applicable law. Such uses include:
    </p>
    <ul>
        <li>
            to tailor content, advertisements, and offers; · to notify you about
            offers, products, and services that may be of interest to you; · to
            provide Services to you and our sponsors; · to audit interactions,
            transactions and other compliance activities; · for other purposes
            disclosed at the time that Individuals provide Personal Information; or
            · Otherwise with your consent.
        </li>
        <li>
            to tailor content, advertisements, and offers; · to notify you about
            offers, products, and services that may be of interest to you; · to
            provide Services to you and our sponsors; · to audit interactions,
            transactions and other compliance activities; · for other purposes
            disclosed at the time that Individuals provide Personal Information; or
            · Otherwise with your consent.
        </li>
        <li>
            to notify you about offers, products, and services that may be of
            interest to you;
        </li>
        <li>
            to provide Services to you and our sponsors;
        </li>
        <li>
            to audit interactions, transactions and other compliance activities;
        </li>
        <li>
            for other purposes disclosed at the time that Individuals provide
            Personal Information; or · Otherwise with your consent.
        </li>
    </ul>

    <h2>
        SPECIAL CIRCUMSTANCES:
    </h2>
    <p>
        Law Enforcement and Safety. We may access, preserve and share your
        information when we have a good faith belief that it is necessary to: (i)
        respond to a legal request (like a search warrant, court order or
        subpoena) or otherwise to take action because we believe it is required or
        advisable under the law; (ii) detect, prevent and address fraud and other
        illegal activity; (iii) respond to your requests for customer service; or
        (iv) limit our legal liability or to protect the rights, property, or
        personal safety of ourselves, any user of our Platforms, a third party or
        the general public including as part of investigations. Business Transfer.
        If we sell or otherwise transfer part or the whole of the CG TECHNOLOGY
        GROUP (Pty) Ltd or our assets to another organization (e.g., in the course
        of a transaction like a merger, acquisition, bankruptcy, dissolution,
        liquidation), your personal information, and any other information
        collected through our Platforms may be among the items sold or
        transferred. The buyer or transferee will be required to honor the
        commitments we have made in this Privacy Policy. Security Notice. In the
        unlikely event that we believe that the security of your personal
        information in our possession or control may have been compromised, we may
        seek to notify you of that development. If a notification is appropriate,
        we would endeavor to do so as promptly as possible under the
        circumstances, and, to the extent you have given us your email address, we
        may notify you by email. You consent to our use of e-mail as a means of
        such notification.
    </p>

    <h2>
        SERVICE PROVIDERS:
    </h2>
    <p>
        We contract with third parties to provide certain services to facilitate
        the operations of our Platforms (&ldquo;service providers&rdquo;), such as
        to manage the analytics information about usage of our Platforms. Our
        service providers will be given access to your information only as is
        reasonably necessary to provide the services for which they are
        contracted. They will be required to abide by the terms of this Privacy
        Policy in their handling of personal information.
    </p>

    <h2>
        WHERE WE STORE YOUR INFORMATION:
    </h2>
    <p>
        Your information collected through a Platform may be stored and processed
        in South Africa or any other country in which we or our service providers
        maintain facilities. We or our service providers may transfer information
        that we collect about you, including personal information across borders
        and from your country or jurisdiction to other countries or jurisdictions
        around the world. If you are located in the European Union or other
        regions with laws governing data collection and use that may differ from
        South African law, please note that we may transfer information, including
        personal information, to a country and jurisdiction that does not have the
        same data protection laws as your jurisdiction. By using a Platform you
        consent to the transfer of information to South Africa or to any other
        country in which we or our service providers maintain facilities and the
        use and disclosure of information about you as described in this Privacy
        Policy.
    </p>

    <h2>
        SECURITY OF YOUR INFORMATION:
    </h2>
    <p>
        We strive to maintain the security of information but cannot guarantee
        that information security measures are fail-safe or guarantee that
        information on a Platform may not be accessed, disclosed, altered, or
        destroyed. Our Platforms maintain technical and organizational steps to
        keep information collected secure, including limiting the number of people
        who have physical access to our database servers and use of electronic
        security systems and password protections to guard against unauthorized
        access.
    </p>

    <h2>
        ONLINE SAFETY:
    </h2>
    <p>
        Involvement in your family&#39;s online lives is one of the most important
        safety tools available. In addition, we encourage you to educate yourself
        and your child on privacy concerns.
    </p>

    <h2>
        CLINICAL TREATMENT:
    </h2>
    <p>
        It&#39;sOk is not intended to clinically treat kids with behavioral and/or
        emotional challenges. It&#39;sOk acts as a platform to allow for emotional
        expression and not psychological treatment and is therefore not
        responsible for any acts carried out by any users of the app. It&#39;sOk
        advises any and all users of the app to seek out psychological treatment
        and advice from a certified psychologist or institution in cases of
        psychological concern.
    </p>
</body>

</html>
  `;
 
  return <div  dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default PrivacyPolicy;
