import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import AllContext from "./shared/context/app_context";
import PrivacyPolicy from "./views/static-documents/privacy-policy/privacy_policy";
import LandingView from "./views/landing.view";
import TermsAndConditions from "./views/static-documents/terms-and-conditions/terms_and_conditions";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AllContext>
      <BrowserRouter>
        {/* <NavigationBar />
        <Home />
        <About />
        <Features />
        <Clients />
        <Footer /> */}
        {/* OTHER  */}
        <Routes>
          <Route path="/" Component={LandingView} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" Component={TermsAndConditions} />
        </Routes>
      </BrowserRouter>
    </AllContext>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
