import About from "./about/about";
import Clients from "./clients/clients";
import Features from "./features/features";
import Footer from "./footer/footer";
import Home from "./home/home";
import NavigationBar from "./navigation-bar/navigation_bar";

const LandingView = () => {
  return (
    <div>
      <NavigationBar />
      <Home />
      <About />
      <Features />
      <Clients />
      <Footer />
    </div>
  );
};

export default LandingView;
