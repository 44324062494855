// const TermsAndConditionsHtml = require("./privacy_policy.html");



const TermsAndConditions = () => {

    const htmlContent = `
    <!DOCTYPE html>
    <html>
    
    <head>
      <title>It'sOk Terms and Conditions</title>
      <style>
        body {
          padding: 101px;
        }
      </style>
    </head>
    
    <body>
      <h1>CG TECHNOLOGY GROUP (Pty) Ltd Terms and Conditions:</h1>
    
      <h2>
        Use of Site Content:
      </h2>
      <p>
        You may access and view the Site Content only for your personal,
        noncommercial use via personal computer or other Internet compatible
        device to enable you to use the Site, provided that you do not remove or
        alter any copyright or other legal notices from the Site or any Site
        Content. You may not (except where we have given you express permission or
        you are otherwise permitted by law) modify, copy, distribute, download,
        upload, post, broadcast or transmit, display, disassemble, perform,
        reproduce, publish, license, decompile, reverse engineer, create
        derivative works from, transfer, sell, or make other use of any of the
        Site Content. Any use of the Site Content, other than as explicitly
        permitted in this paragraph, is unauthorized and may be a violation of
        copyright or other proprietary rights or other applicable federal or state
        laws of South Africa or other countries. The Site is for your
        informational purposes only and is not intended to substitute for
        appropriate professional consultation and advice. The opinions expressed
        are for informational purposes only and are not to be considered by you,
        or anyone, as appropriate or professional, or diagnostic in any respect.
      </p>
    
      <h2>Accessing the Site and Account Security; User Information:</h2>
      <p>
        The app is intended for the use of students, their parents and the school. Please see the privacy policy regarding
        abiding by the Protection of People&#39;s Information Act 4 and the Children&#39;s Online Privacy Protection Act in
        relation to how the app protects children/students. The app is designed and intended to be used and set up in a
        specific way for the sole purpose that the parent creates the child/students account. By the parent or legal
        guardian registering for the app and creating their child/children&#39;s account the parent or legal guardian
        automatically agrees to the terms and conditions and privacy policy of the app.
    
        We reserve the right to withdraw or amend the Site, and any service or material we provide on the Site, at our sole
        discretion without notice. We will not be liable if for any reason if all or any part of the Site is unavailable at
        any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site,
        to users, including registered users.
    
        To access the Site or some of the resources it offers, you may be asked to provide certain registration details or
        other information including personalized information. It is a condition of your use of the Site that all the
        information you provide on the Site is correct, current and complete and you acknowledge that it is your
        responsibility to keep it so. You agree that all information you provide to register with the Site or otherwise,
        including but not limited to the use of any interactive features on the Site, is governed by our Privacy Policy,
        which is incorporated herein by reference, and you consent to all actions we take with respect to your information
        consistent with our Privacy Policy.
    
        If you choose, or are provided with, a user name, password or any other piece of information as part of our security
        procedures, you must treat such information as confidential, and you must not disclose it to any other person or
        entity. You also acknowledge that your account is personal to you and agree not to provide any other person with
        access to the Site or portions of it using your user name, password or other security information. You agree to
        notify us immediately of any unauthorized access to or use of your user name or password or any other breach of
        security.
    
        You also agree to ensure that you exit from your account at the end of each session. You should use particular
        caution when accessing your account from a public or shared computer so that others are not able to view or record
        your password or other personal information.
    
        In the typical course of operation of the Site, we may process anonymized user information through the Google
        Analytics program and as a condition of your use of the Site, you acknowledge and consent to such processing. We may
        use your user ID in combination with Google Analytics and related programs to track and analyze the pages of the
        Site you visit. We do this only to better understand how you use the Site and the other services, in order to
        enhance the user experience; and to tailor our business and activities accordingly, both generally and specifically
        to you. Google Analytics does not provide us with any personal information.
    
        To provide Site users with more choice on how their information is collected by Google Analytics, Google has
        developed the Google Analytics Opt-out Browser Add-on. The add-on communicates with the Google Analytics JavaScript
        (ga.js) to indicate that information about the website visit should not be sent to Google Analytics. The Google
        Analytics Opt-out Browser Add-on does not prevent information from being sent to the Site itself or to other web
        analytics services.
    
        We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us,
        at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any
        provision of these Terms and Conditions.
    
      </p>
      <h2>
        Consideration:
      </h2>
      <p>
        You acknowledge and agree that your access to and use of the Site, the
        Site Content and the services provided through the Site are valuable
        benefits that you receive by agreeing to and complying with the terms and
        conditions of this Agreement.
      </p>
    
      <h2>
        User Submissions and Forum Posts; Moderation and Editorial Control:
      </h2>
      <p>
        We do not ask for nor do we wish to receive any confidential, secret or
        proprietary information or other material from you through the Site, by
        email or in any other way. If you post, upload, transmit or submit any
        materials, content (including, for example, any photographs), information
        or ideas via the Site (collectively, &ldquo;Your Content&rdquo;),
        including through any forum, message board, blog, chat or other online
        interface associated with the Site (the &ldquo;Forum(s)&rdquo;), (i) you
        represent and warrant that Your Content is original to you, that no other
        party has any rights thereto, and that any &ldquo;moral rights&rdquo; in
        Your Content have been waived, and (ii) you grant to CG TECHNOLOGY GROUP
        (Pty) Ltd a non-exclusive, fully-paid, royalty-free, unrestricted,
        perpetual, irrevocable, fully transferable, assignable and sublicensable,
        and worldwide license in all current and future media to use, reproduce,
        modify, adapt, publish, publicly perform and display, distribute,
        sublicense, create derivative works, sell, and otherwise use Your Content
        for any purpose CG TECHNOLOGY GROUP (Pty) Ltd chooses, commercial or
        otherwise, in its sole discretion, without any compensation to you and in
        accordance with our Privacy Policy. We cannot be responsible for
        maintaining Your Content that you provide to us, and we may delete or
        destroy Your Content at any time. You shall not be entitled to any rights
        of attribution in connection with any use of Your Content. CG TECHNOLOGY
        GROUP (Pty) Ltd may alter, edit, refuse to post or remove any postings or
        content, in whole or in part, for any reason and to disclose such
        materials and the circumstances surrounding their transmission to any
        third party in order to satisfy any applicable law, regulation, legal
        process or governmental request and to protect ourselves, our clients,
        sponsors, users and visitors. We may also impose limits on certain
        features or restrict your access to part or all of the features or
        services without notice or penalty if we believe you are in breach of this
        Agreement or applicable law, or for any other reason without notice or
        liability. In addition to any prohibited activities described in this
        Agreement, when using any Forum you must not delete or revise any material
        posted by any other person or entity, or submit, upload or otherwise post:
      </p>
      <ol>
        <li>
          statements or materials that are libelous or defame, harass, abuse,
          stalk, threaten, intimidate or in any way infringe on the any rights of
          any third party, including other users;
        </li>
        <li>
          statements or materials that are bigoted, hateful, racially offensive,
          vulgar, obscene, pornographic, profane, or otherwise objectionable,
          including language or images that typically would not be considered
          socially or professionally responsible or appropriate in person;
        </li>
    
        <li>
          material that is likely to be reasonably perceived as objectionable or
          restricts or inhibits any person or entity from using or enjoying any
          interactive features or other portions of the Site, or which may expose
          us or the Site&#39;s users to harm or liability of any nature;
        </li>
    
        <li>
          statements or materials that violate other contractual or fiduciary
          rights, duties or agreements;
        </li>
        <li>
          personal information, pictures, videos or any other media of another
          person without their express permission;
        </li>
        <li>
          statements or materials that in any way harm minors or any third party;
        </li>
        <li>
          anything that violates the privacy or publicity rights of any other
          person, including, without limitation, posting any personal identifying
          information of another individual, including, without limitation,
          addresses, phone numbers, email addresses, Social Security numbers, or
          credit card numbers;
        </li>
        <li>
          statements or materials that constitute junk mail, spam or unauthorized
          advertising or promotional materials, including, without limitation,
          links to commercial products or services or any political campaigning;
          or
        </li>
        <li>
          material that infringes, or that may infringe, any patent, trademark,
          trade secret, copyright or other intellectual or proprietary right of
          any party, or that you otherwise do not have the right to make
          available, or information for which you have any obligation of
          confidentiality, without the express permission of the owner of the
          copyright, trademark or other proprietary right. We don&#39;t have any
          express burden of responsibility to provide any user with indications,
          markings or anything else that may aid any user in determining whether
          the material in question is copyrighted or trademarked. Users shall be
          solely liable for any damage resulting for infringements of copyrights,
          trademarks, proprietary rights or any other harm resulting from such
          submission.
        </li>
      </ol>
    
      <p>
        Pursuant to the Termination provisions below, any user failing to comply
        with these guidelines may be expelled from and refused continued access to
        the Forums in the future. We may remove or alter any user-created content
        at any time for any reason. Materials posted and/or uploaded to the
        various Forums may be subject to size, usage and other limitations. You
        are responsible for adhering to such limitations. We expressly disclaim
        all responsibility and endorsement and make no representation as to the
        validity of any opinion, advice, information or statement made or
        displayed in these Forums by third parties, nor are we responsible for any
        errors or omissions in such postings, or for hyperlinks embedded in any
        messages. Under no circumstances will we be liable for any loss or damage
        caused by your reliance on information obtained through these Forums. The
        opinions expressed in these Forums are solely the opinions of the users,
        and do not reflect our opinions. In addition, we reserve the right to
        contact users to inform them of policies, hide users&#39; posts or delete
        users&#39; accounts without warning or notice in advance, for any reason,
        including but not limited to the violation of this Agreement.
      </p>
    
      <h2>
        Responsibility and permission:
      </h2>
    
      <p>
        All responsibility for any actions or consequences that follow the use of
        the app will fall to the parent or legal guardian of the child/student. In
        agreeing to these terms and conditions you, the parent, give full
        permission to the child to consume the content of this application as well
        as use the tools made available on this application to their maximum
        capacity. The parent/legal guardian is responsible for not sharing any
        unique codes that are received by the parent from the school or the
        application. These unique codes exist to enhance the security measures of
        the application. These unique codes exist in the form of a unique school
        code and a unique password generated by the app for the child. The parent
        bares full responsibility for keeping these codes safe and not sharing
        these codes with anyone else besides for sharing the unique password to
        their child in order to create the child account. The content consumed on
        the application is created in a way to allow the child to learn more about
        their emotions and is in no way made to replace or assist in any form of
        clinical treatment. The responsibility of treating depression, anxiety or
        any other emotion that arises in the child is not the responsibility of CG
        TECHNOLOGY GROUP (Pty) Ltd.
      </p>
    
      <h2>
        Prohibited User Conduct:
      </h2>
      <p>
        You warrant and agree that, while accessing or using the Site, you will
        not:
      </p>
    
      <ul>
        <li>
          impersonate any person or entity or misrepresent your affiliation with
          any other person or entity, whether actual or fictitious, including
          anyone from the Site;
        </li>
        <li>
          insert your own or a third party&#39;s advertising, branding or other
          promotional content into any of the Site Content, materials or services,
          or buy or sell any products or services from third parties through the
          Site;
        </li>
        <li>
          obtain or attempt to gain unauthorized access to other computer systems,
          materials, information or any services available on or through the Site
          through any means, including through means not intentionally made
          publicly available or provided for through the Site;
        </li>
        <li>
          engage in spidering, &ldquo;screen scraping,&rdquo; &ldquo;database
          scraping,&rdquo; harvesting of email addresses or other personal
          information, or any other automatic or unauthorized means of accessing,
          logging-in or registering on the Site, or obtaining lists of users or
          other information from or through the Site, including, without
          limitation, any information residing on any server or database connected
          to the Site;
        </li>
        <li>
          use the Site or its features and services in any manner that could
          interrupt, damage, disable, overburden or impair the Site, including
          through posting of files that contain malicious code, viruses, corrupted
          files, or any other similar software or programs, or interfere with any
          other party&#39;s use and enjoyment of the Site, including, without
          limitation, sending mass unsolicited messages or &ldquo;flooding&rdquo;
          servers with requests;
        </li>
        <li>
          use the Site or its services in violation of the intellectual property
          or other proprietary or legal rights of the CG TECHNOLOGY GROUP (Pty)
          Ltd or any third party;
        </li>
        <li>
          use the Site or its services in violation of any applicable law or to
          engage in any criminal or tortious activity;
        </li>
        <li>
          attempt (or encourage or support anyone else&#39;s attempt) to
          circumvent, reverse engineer, decrypt, or otherwise alter or interfere
          with the Site or its services.
        </li>
      </ul>
    
      <p>
        You alone are responsible for the content and consequences of any of your
        activities while you are visiting or using the Site. By creating your
        children&#39;s account for the application you as the parent or legal
        guardian take full responsibility for any consequences or actions that
        take place during or after the use of the application.
      </p>
    
      <h2>
        Indemnification:
      </h2>
    
      <p>
        You agree to indemnify and hold harmless CG TECHNOLOGY GROUP (Pty) Ltd,
        and its and their respective affiliates, directors, trustees, officers,
        employees and agents from and against any and all claims, liabilities, and
        expenses (including reasonable legal fees) that may arise from your use of
        the Site in any way, your placement or transmission of any message,
        content, information, software or other materials on, to or through the
        Site or your violation of law or breach of the terms of this Agreement. CG
        TECHNOLOGY GROUP (Pty) Ltd reserve the right to assume the exclusive
        defense and control of any matter otherwise subject to indemnification by
        you, and in such case, you agree to cooperate with the defense of any such
        claims by CG TECHNOLOGY GROUP (Pty) Ltd.
      </p>
    
      <h2>
        General Disclaimer and Limitation of Liability:
      </h2>
      <p>
        CG TECHNOLOGY GROUP (Pty) Ltd controls and operates the Site from offices
        in South Africa. We make no representation that Site Content is
        appropriate or authorized for use in all countries, states, provinces,
        counties or any other jurisdictions. If you choose to access the Site, you
        do so on your own initiative and risk, and you are responsible for
        compliance with all applicable laws if and to the extent such laws and
        restrictions are applicable. The Site provides information of a general
        nature only and you are responsible for determining whether it applies to
        your specific situation. CG TECHNOLOGY GROUP (Pty) Ltd specifically
        disclaims any liability concerning any action that any person may take
        based on any information or guidance provided at the Site. CG TECHNOLOGY
        GROUP (Pty) Ltd does not guarantee the accuracy, timeliness, correctness,
        completeness, performance or fitness for a particular purpose of the Site
        or any of the Site Content. CG TECHNOLOGY GROUP (Pty) Ltd is not liable
        for any errors, omissions, or inaccurate Site Content on the Site. THE
        SITE AND THE SITE CONTENT ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS
        AVAILABLE&rdquo; WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION,
        DATA, DATA PROCESSING SERVICES, UPTIME OR UNINTERRUPTED ACCESS, ANY
        WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY OR USEFULNESS OF SITE
        CONTENT AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
        QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NONINFRINGEMENT, WHICH
        ARE EXCLUDED FROM THIS AGREEMENT TO THE EXTENT THAT THEY MAY BE EXCLUDED
        AS A MATTER OF LAW. CG TECHNOLOGY GROUP (PTY) LTD DOES NOT WARRANT THAT
        THE SITE OR THE SERVICES, CONTENT, FUNCTIONS OR MATERIALS CONTAINED
        THEREIN WILL BE TIMELY, SECURE, UNINTERRUPTED, OR OPERATED FREE OF DELAYS
        IN TRANSMISSION, FAILURE IN PERFORMANCE, COMPUTER VIRUSES, INACCURACIES,
        ERRORS, OR DEFECTS. YOU USE THE SITE AT YOUR OWN RISK. CG TECHNOLOGY GROUP
        (PTY) LTD ALSO ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY
        DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT OR OTHER
        PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING IN THE SITE OR
        YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO
        FROM THE SITE. YOU ARE RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES
        AND VIRUS CHECKS (INCLUDING ANTI-VIRUS AND OTHER SECURITY CHECKS) TO
        SATISFY YOUR PARTICULAR REQUIREMENTS FOR THE ACCURACY AND SECURITY OF DATA
        INPUT AND OUTPUT. CG TECHNOLOGY GROUP (PTY) LTD MAKES NO WARRANTY THAT THE
        SITE WILL MEET USERS&#39; REQUIREMENTS. NO ADVICE, RESULTS OR INFORMATION,
        WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITE SHALL
        CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. EXCEPT AS SET OUT BELOW,
        UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT,
        TORT OR NEGLIGENCE, WILL CG TECHNOLOGY GROUP (PTY) LTD BE LIABLE FOR ANY
        DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES
        THAT ARISE OUT OF OR IN CONNECTION WITH THE USE OF THE SITE OR WITH THE
        DELAY OR INABILITY TO USE THE SITE, OR FOR ANY INFORMATION, SOFTWARE,
        PRODUCTS OR SERVICES OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
        OF THE USE OF THE SITE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THOSE
        ASPECTS OF THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL CG
        TECHNOLOGY GROUP (PTY) LTD BE LIABLE FOR OR IN CONNECTION WITH ANY CONTENT
        POSTED, TRANSMITTED, EXCHANGED OR RECEIVED BY OR ON BEHALF OF ANY USER OR
        OTHER PERSON ON OR THROUGH THE SITE (INCLUDING, WITHOUT LIMITATION, ANY
        SUBMITTED MATERIALS). IN NO EVENT SHALL CG TECHNOLOGY GROUP (PTY) LTD
        TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
        EXCEED, IN THE AGGREGATE, THE AMOUNT, IF ANY, PAID BY YOU TO US FOR YOUR
        USE OF THE SITE OR FOR ANY OF YOUR ACTIVITIES ON THE SITE.
      </p>
    
      <h2>
        TERMINATION:
      </h2>
    
      <p>
        CG TECHNOLOGY GROUP (Pty) Ltd may terminate, change, suspend or
        discontinue any aspect of the Site at any time. CG TECHNOLOGY GROUP (Pty)
        Ltd may restrict, suspend or terminate your access to the Site and/or this
        Agreement if we reasonably believe or suspect you have acted
        inappropriately on the Site or you are in breach of these Terms of Use or
        applicable law, or for any other reason without notice or liability.
        Additionally, CG TECHNOLOGY GROUP (Pty) Ltd has a policy of terminating
        use or access privileges to the Site of users who are repeat infringers of
        intellectual property rights. You may terminate this Agreement by
        discontinuing your use of and access to the Site. This Agreement is
        effective until terminated by you or by CG TECHNOLOGY GROUP (Pty) Ltd for
        any reason, with or without notice. Upon any termination of this
        Agreement, you agree to discontinue your use and access of the Site and to
        immediately destroy all Site Content obtained from it.
      </p>
    
      <h2>
        Miscellaneous:
      </h2>
      <p>
        This Agreement shall be deemed to include all other notices, policies,
        disclaimers, and other terms contained in the Site, including our Privacy
        Policy provided, however, that in the event of a conflict between such
        other terms and the terms of this Agreement, the terms of this Agreement
        shall prevail. You agree that CG TECHNOLOGY GROUP (Pty) Ltd may assign any
        of its rights and/or transfer, sub-contract or delegate any of its
        obligations under this Agreement. This Agreement is personal to you and
        you may not transfer or assign it to a third party. This Agreement is
        governed by and is construed in accordance with the laws of South Africa,
        without regard to conflicts of law provisions. You consent to the
        exclusive jurisdiction and venue of courts in South Africa. in all
        disputes arising out of or relating to your use of the Site.
        Notwithstanding the foregoing, CG TECHNOLOGY GROUP (Pty) Ltd shall have
        the right to bring action against visitors of the Site in courts of
        competent jurisdiction in the jurisdiction in which such visitors reside
        or are located: (i) to seek injunctive relief against such visitor, (ii)
        to obtain a judgment against such visitor where a judgment by a South
        African court will, or may, not be enforced by the jurisdiction in which
        such visitor resides or is located, or (iii) to enforce a judgment
        obtained against such visitor in a South African court. Our failure to
        exercise or enforce any right or provision of this Agreement shall not
        constitute a waiver of such right or provision. If any part of this
        Agreement is determined to be invalid or unenforceable under applicable
        law including but not limited to the warranty disclaimers and liability
        limitations stated above, then the invalid or unenforceable provision will
        be deemed superseded by a valid, enforceable provision that most closely
        matches the intent of the original provision and the remainder of the
        Agreement shall continue in effect. This Agreement constitutes the entire
        agreement between you and CG TECHNOLOGY GROUP (Pty) Ltd with respect to
        the Site and it supersedes all prior or contemporaneous communications and
        proposals, whether electronic, oral or written, between you and CG
        TECHNOLOGY GROUP (Pty) Ltd with respect to the Site. A printed version of
        this Agreement and of any notice given in electronic form will be
        admissible in judicial or administrative proceedings based upon or
        relating to this Agreement to the same extent as other business documents
        and records originally generated and maintained in printed form. Any
        rights not expressly granted in this Agreement are reserved to CG
        TECHNOLOGY GROUP (Pty) Ltd.
      </p>
    </body>
    
    </html>
    `;
   
    return <div  dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  
  export default TermsAndConditions;
  