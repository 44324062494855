import FeatureCard from "./feature_card";

const Features = () => {
  return (
    <div id="features">
      <section
        className="services__area-2 mt--120 pt-150 pb-100 p-relative"
        // style={{
        //   background: `url(assets/img/bg/wave-bg-2.png)`,
        //   backgroundPosition: "bottom",
        //   backgroundSize: "cover",
        // }}
      >
        <div>
          <div className="about__area-2 pt-10">
            <div className="center-container-row">
              <div className="col-xl-5 col-lg-6">
                <div className="about__content">
                  <div className="section__title section__title-3 mb-25">
                    <h2></h2>
                  </div>
                  <p className="secondary__title pl-20 pr-20 text-center">
                    It'sOk is a cutting-edge mental wellbeing app that makes it
                    easy for schools to prioritise wellbeing. <br /> <br /> Here
                    is what you can expect.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="center-container-row">
            <FeatureCard
              image="1"
              title="Mood Check Ins"
              subtitle="Designed to be quick and easy, and the benefits can be life-changing."
            />
            <FeatureCard
              image="2"
              title="Science-backed Content"
              subtitle="Meditations and Life Skills to aid you"
            />
          </div>
          <div className="center-container-row">
            <FeatureCard
              image="3"
              title="Powerful Analytics"
              subtitle="Patterns and trends to gain a deeper understanding"
            />
            <FeatureCard
              image="4"
              title="Daily Reminders"
              subtitle="Form a habit of checking in with yourself"
            />
          </div>
          <div className="center-container-row">
            <FeatureCard
              image="3"
              title="Speak to a Professional"
              subtitle="Chat to school psychologists through the app"
            />
            <FeatureCard
              image="4"
              title="Red Flag Feature"
              subtitle="Anytime a student is flagged for self-harm, key school personnel will be notified immediately."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
